import { template as template_474537fbe9464b8c9c60506cb1c7d8f3 } from "@ember/template-compiler";
const FKLabel = template_474537fbe9464b8c9c60506cb1c7d8f3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_927358d1e3ac41d4951378a7715e8df6 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_927358d1e3ac41d4951378a7715e8df6(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
